<template>
  <div>
    <div class="mb-1">
      <span
        class="link"
        @click="back"
      >
        Назад
      </span>
    </div>
    <div class="container card p-2">
      <h1>{{ $t('Перемещение товаров') }}</h1>
      <div class="row">
        <div class="col-6">
          <p v-if="model.from_warehouse">
            <b>С какого склада:</b> {{ model.from_warehouse.name_uz }}
          </p>
          <p v-if="model.to_warehouse">
            <b>На какой склад:</b> {{ model.to_warehouse.name_uz }}
          </p>
          <p><b>Комментарий</b> {{ model.comment }}</p>
        </div>
        <hr>
        <div class="col-12">
          <h3>Товары</h3>
          <div v-if="model.product_transfers && model.product_transfers.length && model.product_transfers[0].id">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Nomi</th>
                  <th>Barcode</th>
                  <th>Soni</th>
                  <th>Narxi</th>
                  <th>Jami</th>
                  <th>Qaytarilgan</th>
                  <th>Sana</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <template v-for="(product,index) in model.product_transfers">
                  <tr
                    v-if="product.id"
                    :key="index"
                  >
                    <td>{{ index+1 }}</td>
                    <td
                      v-if="product.product_category"
                      class="text-primary"
                    >
                      {{ product.product_category.name_uz }}
                    </td>
                    <td>{{ product.barcode }}</td>
                    <td>{{ product.quantity }} dona</td>
                    <td v-if="product.id">
                      {{ product.product_category.price_dollar|formatPrice }} $
                    </td>
                    <td v-if="product.id">
                      {{ (product.quantity*product.product_category.price_dollar)|formatPrice }} $
                    </td>
                    <td>
                      <div v-if="product.rejected_quantity">
                        {{ product.rejected_quantity }}
                      </div>
                      <div v-else>
                        0
                      </div>
                    </td>
                    <td>
                      {{ product.created_at?formatDateYmd(product.created_at):'' }}
                    </td>
                    <td>
                      <b-button
                        v-if="product.quantity !=0"
                        v-can="'warehouses.parties-reject-product'"
                        variant="outline-danger"
                        class="btn-tour-skip"
                        size="sm"
                        @click="rejectProduct(product)"
                      >
                        <feather-icon
                          icon="CornerUpLeftIcon"
                          size="12"
                        />
                        <span class="mr-25 align-middle">Возврат товар</span>
                      </b-button>
                      <div
                        v-else
                        class="text-muted"
                      >
                        Qaytarilgan
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div v-else>
            Нет информации
          </div>
        </div>
        <div
          v-if="model.status==0"
          class="col-12"
        >
          <b-button
            type="submit"
            class="mt-5 float-center w-100"
            variant="primary"
            @click="confirmModal=true"
          >
            Получить товар
          </b-button>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <div v-if="rejectModal">
      <b-modal
        v-model="rejectModal"
        size="sm"
        centered
        title="Возврат товар"
        hide-footer
        no-close-on-backdrop
        @close="rejectModal=false"
        @cancel="rejectModal=false"
      >
        <h4>{{ form.product_transfer_name }}</h4>
        <validation-observer
          ref="form"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <b-form-group
              :label="$t('Количество')"
              label-for="quantity"
            >
              <validation-provider
                #default="{ errors }"
                name="quantity"
                rules="required"
              >
                <b-form-input
                  id="quantity"
                  v-model="form.quantity"
                  :state="errors.length > 0 ? false:null"
                  name="quantity"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('Комментарий')"
              label-for="comment"
            >
              <validation-provider
                #default="{ errors }"
                name="comment"
                rules="required"
              >
                <b-form-textarea
                  id="comment"
                  v-model="form.comment"
                  :state="errors.length > 0 ? false:null"
                  name="comment"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              type="submit"
              class="mt-5 float-right"
              variant="primary"
              @click="rejectPartyProduct"
            >
              Сохранить
            </b-button>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
    <!-- Confirm Product -->
    <div v-if="confirmModal">
      <b-modal
        v-model="confirmModal"
        size="sm"
        centered
        title="Получить товар"
        hide-footer
        no-close-on-backdrop
        @close="confirmModal=false"
        @cancel="confirmModal=false"
      >
        <validation-observer
          ref="form"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <b-form-group
              :label="$t('Комментарий')"
              label-for="confirmation_comment"
            >
              <validation-provider
                #default="{ errors }"
                name="confirmation_comment"
                rules="required"
              >
                <b-form-textarea
                  id="confirmation_comment"
                  v-model="form.confirmation_comment"
                  :state="errors.length > 0 ? false:null"
                  name="confirmation_comment"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              type="submit"
              class="mt-5 float-right"
              variant="primary"
              @click="confirmPartyProduct"
            >
              Подтвердить
            </b-button>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { showToast } from '@/utils/toast'

export default {
  name: 'Show',
  components: {
    ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      rejectModal: false,
      confirmModal: false,
      required,
      form: {
        comment: '',
        confirmation_comment: '',
        quantity: null,
        product_transfer_id: null,
        product_transfer_name: '',

      },
    }
  },
  computed: {
    ...mapGetters({
      model: 'warehouse/GET_TRANSFER_ITEM',
    }),
    transferId() {
      return this.$route.params.id
    },
  },
  watch: {
  },
  mounted() {
    this.getWarehousAction({ id: this.transferId, relations: 'toWarehouse|fromWarehouse|createdUser|ProductTransfers.productCategory', with_barcode: 1 })
    if (this.$route.params.isTransfer) {
      this.confirmModal = true
    }
  },
  methods: {
    rejectProduct(product) {
      this.rejectModal = true
      this.form.product_transfer_id = product.id
      this.form.product_transfer_name = product.product_category.name_uz
      this.form.quantity = product.quantity
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    confirmPartyProduct() {
      const valid = this.validationForm()
      if (valid) {
        this.form.product_transfer_id = this.$route.params.id
        this.confirmProductTransfer(this.form)
          .then(res => {
            showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
            this.confirmModal = false
            this.goTo('WarehouseTransferProduct')
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    rejectPartyProduct() {
      const valid = this.validationForm()
      if (valid) {
        this.rejectProductTransfer(this.form).then(res => {
          this.getWarehousAction({ id: this.transferId, relations: 'toWarehouse|fromWarehouse|createdUser|ProductTransfers.productCategory', with_barcode: 1 }).then(res => {
            this.rejectModal = false
            showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          })
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    ...mapActions({
      getWarehousAction: 'warehouse/transferProductsShow',
      rejectProductTransfer: 'warehouse/rejectProductTransfer',
      confirmProductTransfer: 'warehouse/confirmTransferProducts',
    }),
  },
}
</script>

<style scoped>

</style>
